// TODO this is just for 2023 Black Friday, we should make it more generic
export function isBlackFridayPromoEnabled(): boolean {
  // Pacific Timezone offset in miliseconds
  const pacificTimezoneOffset = -8 * 60 * 60 * 1000;
  const now = new Date();
  const startDate = new Date("2023-11-09T00:00:00.000Z");
  const endDate = new Date("2023-11-30T23:59:59.999Z");

  const pacificNow = new Date(now.getTime() + pacificTimezoneOffset);
  const pacificStartDate = new Date(startDate.getTime() + pacificTimezoneOffset);
  const pacificEndDate = new Date(endDate.getTime() + pacificTimezoneOffset);

  return pacificNow >= pacificStartDate && pacificNow <= pacificEndDate;
}

/**
 * Format a date to a human-readable relative time string (e.g. "2 days ago")
 */
export function formatDistanceToNow(date: Date): string {
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  if (diffInSeconds < 60) {
    return "just now";
  }

  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) {
    return `${diffInMinutes} ${diffInMinutes === 1 ? "minute" : "minutes"} ago`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours} ${diffInHours === 1 ? "hour" : "hours"} ago`;
  }

  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays < 30) {
    return `${diffInDays} ${diffInDays === 1 ? "day" : "days"} ago`;
  }

  const diffInMonths = Math.floor(diffInDays / 30);
  if (diffInMonths < 12) {
    return `${diffInMonths} ${diffInMonths === 1 ? "month" : "months"} ago`;
  }

  const diffInYears = Math.floor(diffInMonths / 12);
  return `${diffInYears} ${diffInYears === 1 ? "year" : "years"} ago`;
}
